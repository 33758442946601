<template>
  <OrganizationsMainStyled>
    <Panel v-for="organization in organizationsMapped" :key="organization.id">
      <template v-slot:header>
        {{ organization.name }}
        <VPopover
          :placement="'bottom-end'"
          :open.sync="isActive"
          :popperOptions="popperOptions"
          :popoverClass="'userGuidePopOver'"
          :container="'#app'"
          v-if="organization.isAdmin"
        >
          <span class="popover-trigger" :class="{ active: isActive }">
            <span> {{ $t('actions.learn_more') }}</span>
            <HelpCircleIcon size="1.2x" />
          </span>
          <template v-slot:popover>
            <UserGuideStyled>
              <div class="title">
                <StarIcon />
                <h5>{{ $t('userGuide.title') }}</h5>
              </div>
              <div class="content">
                {{ $t('userGuide.content') }}
              </div>
              <template>
                <ActionsStyled>
                  <ButtonBorderedStyled @click="close">
                    {{ $t('actions.ok') }}
                  </ButtonBorderedStyled>
                  <AButtonSolid v-if="pdfLink" :href="pdfLink" target="_blank" @click="close">
                    {{ $t('actions.check_it_out') }}
                  </AButtonSolid>
                </ActionsStyled>
              </template>
            </UserGuideStyled>
          </template>
        </VPopover>
      </template>
      <template v-slot:main>
        <div class="settings" v-if="organization.isAdmin">
          <div class="title">
            {{ $tc('setting', 2) }}
          </div>
          <div class="cell option">
            <OnOffToggle
              class="on-off-toggle"
              :value="organization.nonAdminUsersCanSeeOtherUsers"
              @toggle="toggleNonAdminUsersCanSeeOtherUsers(organization)"
            />
            <span>{{ $t('nonAdminUsersCanSeeOtherUsers') }}</span>
          </div>
          <div class="cell option">
            <OnOffToggle
              class="on-off-toggle"
              :value="organization.dashboardSharingEnabledForAllUsers"
              :disabled="!organization.nonAdminUsersCanSeeOtherUsers"
              @toggle="toggleUserDashboardSharing(organization)"
            />
            <span>{{ $t('dashboardSharingForAllUsers') }}</span>
          </div>
        </div>
        <div class="members">
          <div class="title">
            {{ $tc('user', 2) }}
          </div>
          <div class="cell username">
            <strong>{{ $tc('username', 1) }}</strong>
          </div>
          <div class="cell email">
            <strong>{{ $tc('email', 1) }}</strong>
          </div>
          <div class="cell role">
            <strong>{{ $tc('role', 1) }}</strong>
          </div>
          <div class="cell spacer"></div>
          <template v-for="membership in organization.memberships">
            <div class="cell username" :key="'username_' + membership.id">
              {{ membership.user.username }}
            </div>
            <div class="cell email" :key="'email_' + membership.id">
              <nobr>{{ membership.user.email }}</nobr>
            </div>
            <div class="cell role" :key="'role_' + membership.id">
              <BasicSelect
                v-if="organization.isAdmin"
                :target="$tc('role', 1)"
                :options="roles"
                :selected="membership.selectedRole"
                @change="setRole($event, membership)"
              />
              <template v-else>
                {{ $tc(`membershipRoles.${membership.role.toLowerCase()}`) }}
              </template>
            </div>
            <div class="cell spacer" :key="'spacer_' + membership.id">
              <BadgeStyled v-if="membership.userId === userId">
                {{ $tc('itIsYou') }}
              </BadgeStyled>
            </div>
          </template>
        </div>
        <div class="subsidiaries">
          <div class="title">
            {{ $tc('subsidiary', 2) }}
          </div>
          <template v-if="organization.subsidiaries.length">
            <div class="cell name">
              <strong>{{ $tc('name', 1) }}</strong>
            </div>
            <div class="cell members">
              <strong>{{ $tc('user', 2) }}</strong>
            </div>
            <div class="cell assets">
              <strong>{{ $tc('asset', 2) }}</strong>
            </div>
            <div class="cell spacer"></div>
            <template v-for="subsidiary in organization.subsidiaries">
              <div class="cell name value" :key="'name_' + subsidiary.id">
                <nobr>{{ subsidiary.name }}</nobr>
              </div>
              <div class="cell members" :key="'members_' + subsidiary.id">
                {{ subsidiary.memberships.length }}
              </div>
              <div class="cell assets" :key="'assets_' + subsidiary.id">
                {{ subsidiary.assets.length }}
              </div>
              <div class="cell actions" :key="'spacer_' + subsidiary.id">
                <template v-if="organization.isAdmin">
                  <ButtonRound :disabled="false" @click="editSubsidiary(subsidiary)">
                    <Edit2Icon />
                  </ButtonRound>
                  <ButtonRound :disabled="false" @click="confirmDeleteSubsidiary(subsidiary, organization)">
                    <XIcon />
                  </ButtonRound>
                </template>
                <template v-else>
                  <ButtonRound :disabled="false" @click="editSubsidiary(subsidiary)">
                    <MoreHorizontalIcon />
                  </ButtonRound>
                </template>
              </div>
            </template>
          </template>
          <div class="no-subsidiaries" v-else>
            <i18n path="messages.noDataWithType">
              <template v-slot:type>
                {{ $tc('subsidiary', 2) }}
              </template>
            </i18n>
          </div>
          <div class="create-subsidiary" v-if="organization.isAdmin">
            <transition name="fade-in-left">
              <template v-if="!organization.isEditing">
                <ButtonSolid @click="organizationInEditModeId = organization.id">
                  <PlusIcon />
                  <span>
                    {{ $tc('actions.create') }}
                  </span>
                </ButtonSolid>
              </template>
              <template v-else>
                <div class="input">
                  <ButtonConfirm @click="organizationInEditModeId = null">
                    <XIcon />
                  </ButtonConfirm>
                  <input v-model="subsidiaryName" />
                  <ButtonConfirm @click="createSubsidiary(organization)" :disabled="canCreateSubsidiary">
                    <CheckIcon />
                  </ButtonConfirm>
                </div>
              </template>
            </transition>
          </div>
        </div>
      </template>
    </Panel>
  </OrganizationsMainStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import permissionsMixin from '@/mixins/permissions'
import { FlashMessages } from '@common/singletons'
import { VPopover } from 'v-tooltip'

import ORGANIZATION_LIST_QUERY from '#/graphql/organizations/list.gql'
import UPDATE_ORGANIZATION_MEMBERSHIP_MUTATION from '#/graphql/user/organizationMembership/update.gql'
import CREATE_SUBSIDIARY_MUTATION from '#/graphql/organizations/subsidiary/create.gql'
import DELETE_SUBSIDIARY_MUTATION from '#/graphql/organizations/subsidiary/delete.gql'
import TOGGLE_ORGANIZATION_USER_VISIBILITY_MUTATION from '#/graphql/organizations/toggleOrganizationUserVisibility.gql'
import TOGGLE_ORGANIZATION_USER_DASHBOARD_SHARE from '#/graphql/organizations/toggleOrganizationUserDashboardShare.gql'
import AVAILABLE_ROLES_QUERY from '#/graphql/misc/availableRoles.gql'

import { PlusIcon, CheckIcon, Edit2Icon, XIcon, MoreHorizontalIcon, HelpCircleIcon } from 'vue-feather-icons'

import { ButtonSolid, ButtonRound as ButtonBase, ButtonBordered } from '@styles/buttons'

import { getUserIdFromToken } from '@common/utils'

import { Panel, BasicSelect, OnOffToggle } from '@common/components'

import { flexCenter, flexStartCenter, buttonReset } from '@styles/mixins'

import chroma from 'chroma-js'

import StarIconSrc from '@/assets/icons/starIcon.svg'

const StarIcon = styled(StarIconSrc)`
  & > path:nth-of-type(1) {
    fill: ${({ theme }) => theme.colors.primary};
  }

  & > path:nth-of-type(2) {
    fill: ${({ theme }) => (theme.isDark ? theme.colors.talpaGrey : theme.colors.gainsBoro)};
  }

  & > path:nth-of-type(3) {
    fill: ${({ theme }) => theme.colors.primary};
  }

  width: 20px;
  height: 20px;
`

const ButtonBorderedStyled = styled(ButtonBordered)`
  padding: 0.5rem 1.5rem;
  color: ${props => props.theme.colors.white};
  background: ${props => chroma(props.theme.colors.muted).darken(2).css()};
  border-color: ${props => chroma(props.theme.colors.muted).darken(2).css()};
  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primaryActive};
  }
`

const OrganizationsMainStyled = styled('div')`
  margin: 1rem;
  > section {
    margin-bottom: 3rem;
  }
  > section > header {
    font-size: 1.1rem;
    justify-content: space-between;
    span {
      font-size: 0.8rem;
      font-weight: normal;
    }
  }  
  > section > main {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
  }
  .settings, .members, .subsidiaries {
    display: grid;
    grid-template-columns: minmax(10rem, min-content) repeat(2, minmax(4rem, min-content)) auto;
    grid-auto-rows: minmax(2rem, min-content);
    grid-gap: 1rem;
    > .title {
      ${flexStartCenter}
      grid-column: span 4;
      font-size: 1.3rem;
      padding: .5rem;
      border-bottom: 1px solid ${p => p.theme.colors.panelHeaderBorderBottom};
      text-transform: capitalize;
    }

    > .cell {
      display: flex;
      align-items: center;
      padding: .5rem;
      word-break;
    }
    .actions {
      display: flex;
    }
  }
  .settings {
    .option {
      grid-column: span 4;
      padding: 1rem;
      .on-off-toggle {
        margin-right: .5rem;
      }
    }
  }
  .members, .subsidiaries {
    .title {
      border-top: 1px solid ${p => p.theme.colors.panelHeaderBorderBottom};
    }
  }
  .no-subsidiaries {
    ${flexCenter}
    grid-column: span 4;
  }
  .create-subsidiary {
    ${flexCenter}
    grid-column: span 4;
    position: relative;
    .fade-in-left-enter-active, .fade-in-left-leave-active {
      position: absolute;
    }
    .input {
      display: flex;
      border-radius: 3px;
      input {
        border-radius: 0;
        border: none;
        padding: .5rem;
        color: ${p => p.theme.colors.navFontNormal};
        background: ${p => chroma(p.theme.colors.navFontNormal).alpha(0.2).css()};
      }
    }
  }
  .popover-trigger {
    display: flex;
    align-items: center;
    cursor: pointer;
    & > svg {
      margin-left: .2rem;
    }
    &:hover {
        color: ${p => p.theme.colors.primaryLighter};      
    }
  }
`
const UserGuideStyled = styled('div')`
  width: 360px;
  box-sizing: border-box;
  padding: 2rem;
  color: ${p => p.theme.colors.navFontNormal};
  background-color: ${p => chroma(p.theme.colors.solidBG).alpha(0.2).css()};
  border-radius: 10px;
  justify-content: start;
  .title {
    ${flexStartCenter}
    align-items: center;
    padding: 0.6rem 0.6rem 0.4rem 0.6rem;
    & > h5 {
      margin: 0 0 0 0.3rem;
      font-weight: normal;
    }
  }
  .content {
    ${flexStartCenter}
    padding: 0.8rem;
    font-size: 0.8rem;
  }
`
const ActionsStyled = styled('div')`
  display: flex;
  justify-content: start;
  padding: 0.2rem;
`

const ButtonRound = styled(ButtonBase)`
  .feather {
    width: 12px;
    height: 12px;
  }
  border-radius: 0;
  margin-left: 0.5rem;
  background: ${p => p.theme.colors.primary};
  &:hover {
    background: ${p => p.theme.colors.primaryActive};
  }
`

const ButtonConfirm = styled(ButtonSolid)`
  margin: 0;
  border-radius: 0;
`

const BadgeStyled = styled('span')`
  font-size: 0.7rem;
  border-radius: 3px;
  padding: 0.25rem;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.green};
`

const AButtonSolid = styled('a')`
  ${buttonReset}
  ${flexCenter}
  margin:  .5rem .5rem .5rem 2.8rem;
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  color: ${props => (props.theme.isDark ? props.theme.colors.black : props.theme.colors.white)}!important;
  background: ${props => props.theme.colors.primary};
  text-decoration: none;
  font-size: 0.8rem;
  &:hover {
    color: ${props => (props.theme.isDark ? props.theme.colors.black : props.theme.colors.white)};
    background: ${props => props.theme.colors.primaryActive};
  }
  &:disabled {
    color: ${props => chroma(props.theme.colors.muted).brighten(1).css()};
    background: ${props => chroma(props.theme.colors.muted).darken(1).css()};
  }
`

export default {
  mixins: [permissionsMixin],
  inject: ['uiSettings'],
  components: {
    OrganizationsMainStyled,
    Panel,
    BasicSelect,
    BadgeStyled,
    ButtonSolid,
    ButtonConfirm,
    ButtonRound,
    OnOffToggle,
    PlusIcon,
    CheckIcon,
    Edit2Icon,
    XIcon,
    MoreHorizontalIcon,
    HelpCircleIcon,
    UserGuideStyled,
    VPopover,
    ButtonBorderedStyled,
    ActionsStyled,
    AButtonSolid,
    StarIcon,
  },
  data() {
    return {
      organizations: [],
      organizationInEditModeId: null,
      userId: getUserIdFromToken(this.$keycloak.token),
      toggledCreateSubsidiary: false,
      isCreatingSubsidiary: false,
      subsidiaryName: '',
      isActive: false,
      pdfBaseUrl: '/PDFDocuments',
      pdfDocumentName: '210119_Manual_User_Management.pdf',
    }
  },
  computed: {
    pdfLink() {
      const language = get(this.uiSettings, 'language', '').toLowerCase()
      if (!language) {
        return null
      }
      return `${this.pdfBaseUrl}/${language}/${this.pdfDocumentName}`
    },
    popperOptions() {
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: true,
          },
        },
      }
    },
    organizationsMapped() {
      return this.organizations.map(organization => ({
        ...organization,
        isEditing: organization.id === this.organizationInEditModeId,
        isAdmin: organization.memberships.find(membership => membership.userId === this.userId && membership.role === 'ADMIN'),
        memberships: organization.memberships.map(membership => ({
          ...membership,
          selectedRole: this.roles.find(f => f.id === membership.role),
        })),
      }))
    },
    canCreateSubsidiary() {
      return !this.isCreatingSubsidiary && this.subsidiaryName.trim().length < 5
    },
    roles() {
      return get(this.availableRoles, 'enumValues', []).map(roleEnum => ({
        id: roleEnum.name,
        label: this.$tc(`membershipRoles.${roleEnum.name.toLowerCase()}`, 1),
      }))
    },
  },
  methods: {
    close() {
      this.isActive = false
      return this.isActive
    },
    async toggleNonAdminUsersCanSeeOtherUsers(organization) {
      try {
        await this.$apollo.mutate({
          mutation: TOGGLE_ORGANIZATION_USER_VISIBILITY_MUTATION,
          variables: {
            where: {
              id: organization.id,
            },
          },
        })
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    async toggleUserDashboardSharing(organization) {
      try {
        await this.$apollo.mutate({
          mutation: TOGGLE_ORGANIZATION_USER_DASHBOARD_SHARE,
          variables: {
            where: {
              id: organization.id,
            },
          },
        })
      } catch (err) {
        FlashMessages.$emit('error', err)
      }
    },
    async createSubsidiary(organization) {
      try {
        this.isCreatingSubsidiary = true
        await this.$apollo.mutate({
          mutation: CREATE_SUBSIDIARY_MUTATION,
          variables: {
            data: {
              organization: {
                connect: {
                  id: organization.id,
                },
              },
              name: this.subsidiaryName,
            },
          },
          update: (store, { data }) => {
            const { organizations } = store.readQuery({
              query: ORGANIZATION_LIST_QUERY,
              variables: {
                where: {
                  memberships_some: {
                    userId: getUserIdFromToken(this.$keycloak.token),
                  },
                },
              },
            })
            const org = organizations.find(f => f.id === organization.id)
            org.subsidiaries.push(data.createSubsidiary)
            store.writeQuery({
              query: ORGANIZATION_LIST_QUERY,
              variables: {
                where: {
                  memberships_some: {
                    userId: getUserIdFromToken(this.$keycloak.token),
                  },
                },
              },
              data: {
                organizations,
              },
            })
            FlashMessages.$emit('success', this.$t('messages.entityCreatedSuccessfully', { entity: this.$tc('subsidiary', 1) }), {
              timeout: 3000,
            })
          },
        })
      } catch (err) {
        FlashMessages.$emit('error', err)
      } finally {
        this.isCreatingSubsidiary = true
        this.organizationInEditModeId = null
      }
    },
    editSubsidiary(subsidiary) {
      this.$router.push({
        name: 'subsidiary',
        params: {
          id: subsidiary.id,
        },
      })
    },
    confirmDeleteSubsidiary(subsidiary, organization) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'Subsidiary',
        onConfirm: this.deleteSubsidiary,
        onConfirmArgs: {
          subsidiary,
          organization,
        },
      })
    },
    async deleteSubsidiary({ subsidiary, organization }) {
      try {
        this.isCreatingSubsidiary = true
        await this.$apollo.mutate({
          mutation: DELETE_SUBSIDIARY_MUTATION,
          variables: {
            where: {
              id: subsidiary.id,
            },
          },
          update: store => {
            const { organizations } = store.readQuery({
              query: ORGANIZATION_LIST_QUERY,
              variables: {
                where: {
                  memberships_some: {
                    userId: getUserIdFromToken(this.$keycloak.token),
                  },
                },
              },
            })
            const org = organizations.find(f => f.id === organization.id)
            org.subsidiaries = org.subsidiaries.filter(f => f.id !== subsidiary.id)
            store.writeQuery({
              query: ORGANIZATION_LIST_QUERY,
              variables: {
                where: {
                  memberships_some: {
                    userId: getUserIdFromToken(this.$keycloak.token),
                  },
                },
              },
              data: {
                organizations,
              },
            })
            FlashMessages.$emit('success', this.$t('messages.entityDeletedSuccessfully', { entity: this.$tc('subsidiary', 1) }), {
              timeout: 3000,
            })
          },
        })
        return true
      } catch (err) {
        FlashMessages.$emit('error', err)
      } finally {
        this.isCreatingSubsidiary = true
        this.organizationInEditModeId = null
      }
    },
    async setRole(role, membership) {
      if (get(membership, '__typename', 'invalid') === 'OrganizationMembership') {
        const res = await this.$apollo.mutate({
          mutation: UPDATE_ORGANIZATION_MEMBERSHIP_MUTATION,
          variables: {
            data: {
              role: role.id,
            },
            where: {
              id: membership.id,
            },
          },
        })
        if (res) {
          FlashMessages.$emit('success', this.$t('messages.entityUpdatedSuccessfully', { entity: this.$tc('member', 1) }), {
            timeout: 3000,
          })
        }
      }
    },
  },
  apollo: {
    organizations: {
      query: ORGANIZATION_LIST_QUERY,
      variables() {
        return {
          where: {
            memberships_some: {
              userId: getUserIdFromToken(this.$keycloak.token),
            },
          },
        }
      },
    },
    availableRoles: {
      query: AVAILABLE_ROLES_QUERY,
      variables() {
        return {
          enumName: 'OrganizationMembershipRoleEnum',
        }
      },
    },
  },
}
</script>
